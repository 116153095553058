import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tooltip/dist/react-tooltip.min.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/auth/Signup.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/ScrollToTopButton.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layout/navbar/Navbar.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Subscription.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/AuthContext.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/cart/CartContext.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/wishlist/WishListContext.js")